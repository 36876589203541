import React, {useEffect, useState} from 'react';
import { Modal, Select } from 'antd';
import Call from '../../../../config/Call';
import Spinner from "../../../base/spiner";

const LINEA=1
const SUBLINEA = 2
const GENERO=3
const SILUETA=4

const ModalAdvancedFilters = ({visible, onClose, filterAdavance }) => {

    const [sp, setSp] = useState(false)
    const [line,setLine]= useState([])
    const [subline,setSubline]=useState([])
    const [gender,setGender]=useState([])
    const [siluet, setSiluet]=useState([])
    const [filters,setFilters]=useState({
        linea: {value:'', id:''},
        sublinea:{value:'', id:''},
        genero:{value:'', id:''},
        silueta:{value:'', id:''}
    });

    useEffect(() => {
        getLine()
        getGender()
    },[])

    useEffect(()=>{
        filterAdavance({
            linea: filters?.linea?.value,
            sublinea: filters?.sublinea?.value,
            genero: filters?.genero?.value,
            silueta: filters?.silueta?.value,

        })
    }, [filters])
    const getLine = async () => {
        try{
            setSp(true);
            const res = await Call('GET', '/productos/lineas/', true, null, false)
            const lineas= res.data.results
            setLine(lineas)
            setSp(false)

        }catch(error){
            setSp(true)
        }
    }
    const getGender = async () => {
        try {
            setSp(true);
            const res = await Call('GET', '/productos/generos/', true, null, false)
            const generos = res.data.results
            setGender(generos)
            setSp(false)
        }catch(error){
            setSp(false);
        }
    }
    const getSublinea = async (id)=>{
        try {
            setSp(true);
            const res = await Call('GET', `/productos/lineas/${id}/sublineas/`, true, null, false)
            const sublines = res.data.results
            setSubline(sublines)
            setSp(false)
        }catch (error){
            setSp(false)
        }
    }
    const getShape= async (id, tipo)=>{
        try{
            setSp(true);

            const infoLinea= filters?.linea?.id
            const infoSublinea= (tipo === SUBLINEA? id : filters?.sublinea?.id)
            const infoGenero=(tipo === GENERO ? filters.genero?.id: id)

            const res= await Call('GET', `/productos/siluetas/?linea_id=${infoLinea}&sublinea_id=${infoSublinea}&genero_id=${infoGenero}`, true, null, false)
            const shapes=res.data.results
            setSiluet(shapes)
            setSp(false)

        }catch (error) {
            setSp(false);
        }
    }
    const handleChange=(e, options, type)=>{
        if(type === LINEA){
            setFilters({
                ...filters,
                linea: {
                    value: options.cod_linea ,
                    id: options.id
                },
                sublinea:{
                    value: '',
                    id: ''
                },
               silueta:{
                    value: '',
                    id: ''
                }
            })
            getSublinea(options.id)
        }else if(type === SUBLINEA){
            setFilters({
                ...filters,
                sublinea: {
                    value: options.cod_sublinea,
                    id: options.id
                },
                silueta:{
                    value: '',
                    id: ''
                }
            })
            if(filters?.genero?.value!==''){
                getShape(options.id, SUBLINEA)
            }
        }else if(type === GENERO){
            setFilters({
                ...filters,
                genero: {
                    value: options.cod_genero,
                    id: options.id
                }
            })
            getShape(options.id)
        }else{
            setFilters({
                ...filters,
                silueta: {
                    value: options.cod_silueta,
                    id: options.id
                }

            })
        }
    }
    return (
        <>
            {
                sp &&
                <Spinner />
            }
            <Modal
                visible={visible}
                onCancel={onClose}
                footer={null}
                centered
                title="Filtros avanzados"
                width={1000}
            >
                <div className="m-regular fs-12 text-danger"><em>*Para realizar la búsqueda todos los campos deben estar llenos</em></div>
                <div className="d-flex flex-wrap gap-c5 justify-content-between">
                    <div className="form-group my-2" style={{display: 'inline-grid'}}>
                        <label className="m-regular">Linea</label>
                        <Select
                            placeholder={'Linea'}
                            style={{width: 200}}
                            onChange={(e, options)=>handleChange(e, options, LINEA)}
                            options={line}
                            value={filters?.linea?.value}
                            notFoundContent={<div className='text-center'>No hay datos</div>}
                            fieldNames={{ label: 'descripcion', value: 'cod_linea', id: 'id' }}
                            showSearch
                            filterOption={(input, option) =>
                                (option.label).toLowerCase().includes(input.toLowerCase())
                            }
                        />
                    </div>

                    <div className="form-group my-2" style={{display: 'inline-grid'}} >
                        <label className="m-regular" htmlFor="exampleInputEmail1">Sublinea</label>
                        <Select
                            placeholder={'Sublinea'}
                            style={{width: 200}}
                            onChange={(e, options)=>{handleChange(e, options, SUBLINEA)}}
                            options={subline}
                            fieldNames={{ label: 'descripcion', value: 'cod_sublinea', id: 'id' }}
                            disabled={filters?.linea?.value === ''}
                            value={filters?.sublinea?.value}
                            notFoundContent={<div className='text-center'>No hay datos</div>}
                            showSearch
                            filterOption={(input, option) =>
                                (option.label).toLowerCase().includes(input.toLowerCase())
                            }
                        />
                    </div>
                    <div className="form-group my-2" style={{display: 'inline-grid'}}>
                        <label className="m-regular">Género</label>
                        <Select
                            placeholder={'Género'}
                            style={{width: 200}}
                            onChange={(e, options)=>{handleChange(e, options, GENERO)}}
                            options={gender}
                            value={filters?.genero?.value}
                            fieldNames={{ label: 'descripcion', value: 'cod_genero', id: 'id' }}
                            notFoundContent={<div className='text-center'>No hay datos</div>}
                            showSearch
                            filterOption={(input, option) =>
                                (option.label).toLowerCase().includes(input.toLowerCase())
                            }
                        />
                    </div>
                    <div className="form-group my-2" style={{display: 'inline-grid'}}>
                        <label className="m-regular">Silueta</label>
                        <Select
                            placeholder={'Silueta'}
                            style={{width: 200}}
                            onChange={(e, options)=>{handleChange(e, options, SILUETA)}}
                            options={siluet}
                            value={filters?.silueta?.value}
                            fieldNames={{ label: 'descripcion', value: 'cod_silueta', id: 'id' }}
                            disabled={(!((filters?.linea?.value !== '') && (filters?.sublinea?.value !== '') && (filters?.genero?.value !== '')))}
                            notFoundContent={<div className='text-center'>No hay datos</div>}
                            showSearch
                            filterOption={(input, option) =>
                                (option.label).toLowerCase().includes(input.toLowerCase())
                            }
                        />
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default ModalAdvancedFilters;